import agronea from './img/agronea.png'
import iagrooldris from './img/iagrooldris.png'
const more = "Zobrazit více"

const allProjects = [
    {
        id: 1,
        image: iagrooldris,
        title: "I. Agro Oldřiš",
        desc: "Statická webová stránka pro I. Agro Oldřiš.",
        link: "https://agro-oldris.cz",
        more: more,
    },
    {
        id: 2,
        image: agronea,
        title: "Agronea a.s. Polička",
        desc: "Web pro Agronea a.s. Polička zemědělské skupiny Jaromíra Tesaře",
        link: "https://www.agronea.cz",
        more: more,
    },
]

export default allProjects